import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="header" id="home">
        <h1 className="logo">Forever Spring Management</h1>
        <p className="subtitle">Navigating capital to where opportunities blossom.</p>
        <div className="scroll-down">
          <a href="#about">Learn More</a>
        </div>
      </header>
      <section className="about" id="about">
        <h2>About Us</h2>
        <p>
          Founded in 2024, Forever Spring Management is a private fund manager focused on fostering growth and innovation. 
          We invest across a wide range of opportunities, including public market equities, private equity, company buyouts, 
          venture capital, and emerging market startups. Our mission is to deliver impactful investments while adapting 
          to an ever-evolving financial landscape.
        </p>
        <p className="contact">
          <a href="mailto:contact@forever-spring.com">contact@forever-spring.com</a>
        </p>
      </section>
    </div>
  );
}
export default App;
